import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs/internal/Subscription';
import { AuthenticationService } from './authentication.service';
import { UserService } from './user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT, Location } from '@angular/common';
import { take } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class UrlService {
  private static url: string;
  private static switchLang: boolean;
  private unsubscribe: Subscription[] = [];
  public urlLang = '';

  public privacyPolicy = '/en/privacy-policy';
  public contactUs = '/en/contact-us';
  public faq = '/en/faq';
  public terms = '/en/terms';
  public plansAddons = '/en/plans-addons';
  public apartmentRental = '/en/apartment-rental';
  public baseUrl = '/en';
  public settings = '/en/manage/settings';
  public listPost = '/en/manage/list';
  public reviewAds = '/en/manage/review-ads';
  public listUsers = '/en/manage/list-users';
  public detailAds = '/en';
  public lists = '/en/apartment-list';

  constructor(
    private translateService: TranslateService,
    private cookieService: CookieService,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(DOCUMENT) private document: Document
  ) {}

  SetLang(language: string) {
    this.unsubscribe.push(
      this.translateService.use(language).subscribe({
        next: (val) => {
          const lang = this.translateService.currentLang;
          if (!lang) return;
          if (lang === 'en') this.urlLang = '/en';
          else this.urlLang = '';
          let url = '';
          if (lang === 'vi') {
            this.privacyPolicy = '/chinh-sach-bao-mat';
            this.contactUs = '/lien-he';
            this.faq = '/faq';
            this.terms = '/dieu-kien';
            this.plansAddons = '/plans-et-ajouts';
            this.apartmentRental = '/cho-thue-can-ho';
            this.baseUrl = '/';
            this.settings = '/manage/setting';
            this.listPost = '/manage/list';
            this.reviewAds = '/manage/review-ads';
            this.listUsers = '/manage/list-users';
            this.detailAds = '/';
            this.lists = '/thue-can-ho';
          } else {
            this.privacyPolicy = '/en/privacy-policy';
            this.contactUs = '/en/contact-us';
            this.faq = '/en/faq';
            this.terms = '/en/terms';
            this.plansAddons = '/en/plans-addons';
            this.apartmentRental = '/en/apartment-rental';
            this.baseUrl = '/en';
            this.settings = '/en/manage/setting';
            this.listPost = '/en/manage/list';
            this.reviewAds = '/en/manage/review-ads';
            this.listUsers = '/en/manage/list-users';
            this.detailAds = '/en';
            this.lists = '/en/apartment-list';
          }
          const addSegment = false;
          const segments = UrlService.url.split('/');
          let segment = '';
          if (segments) segment = segments[segments.length - 1];
          if (UrlService.url === '/terms' || UrlService.url === '/dieu-kien')
            url = this.terms;
          else if (
            UrlService.url.includes('/privacy-policy') ||
            UrlService.url.includes('/chinh-sach-bao-mat')
          )
            url = this.privacyPolicy;
          else if (
            UrlService.url.includes('/contact-us') ||
            UrlService.url.includes('/lien-he')
          )
            url = this.contactUs;
          else if (UrlService.url.includes('/faq')) url = this.faq;
          else if (
            UrlService.url.includes('/apartment-rental') ||
            UrlService.url.startsWith('/cho-thue-can-ho')
          )
            url = this.apartmentRental;
          else if (UrlService.url.includes('/manage/setting'))
            url = this.settings;
          else if (UrlService.url.includes('/manage/list-users'))
            url = this.listUsers;
          else if (UrlService.url.includes('/manage/list')) url = this.listPost;
          else if (UrlService.url.includes('/manage/review-ads'))
            url = this.reviewAds;
          else if (UrlService.url === '' || UrlService.url === '/en')
            url = this.baseUrl;
          else if (
            UrlService.url.startsWith('/chitiet') ||
            UrlService.url.startsWith('/en/chitiet')
          ) {
            let pos = UrlService.url.indexOf('/');
            let count = 1;
            let beforeSecondSlash;
            let afterSecondSlash;

            if (lang === 'vi') {
              while (pos !== -1 && count < 3) {
                pos = UrlService.url.indexOf('/', pos + 1);
                count++;
              }
              beforeSecondSlash = UrlService.url.substring(0, pos);
              afterSecondSlash = UrlService.url.substring(pos);
              beforeSecondSlash = beforeSecondSlash
                .replace('apartment', 'chung-cu')
                .replace('service-apartment', 'can-ho-dich-vu')
                .replace('motel-room', 'phong-tro')
                .replace('penthouse', 'penthouse')
                .replace('dormitory', 'tap-the-cu-xa')
                .replace('/en/', '/');
            } else {
              while (pos !== -1 && count < 4) {
                pos = UrlService.url.indexOf('/', pos + 1);
                count++;
              }
              beforeSecondSlash = UrlService.url.substring(0, pos);
              afterSecondSlash = UrlService.url.substring(pos);
              beforeSecondSlash = beforeSecondSlash
                .replace('chung-cu', 'apartment')
                .replace('can-ho-dich-vu', 'service-apartment')
                .replace('phong-tro', 'motel-room')
                .replace('penthouse', 'penthouse')
                .replace('tap-the-cu-xa', 'dormitory');
              if (!beforeSecondSlash.includes('/en/'))
                beforeSecondSlash = '/en' + beforeSecondSlash;
            }
            url = beforeSecondSlash + afterSecondSlash;
          } else if (
            UrlService.url.startsWith('/thue-can-ho') ||
            UrlService.url.startsWith('/apartment-list') ||
            UrlService.url.startsWith('/en/apartment-list')
          ) {
            let pos = UrlService.url.indexOf('/');
            let count = 1;
            let beforeSecondSlash;
            let afterSecondSlash;
            let posEnd = UrlService.url.indexOf('?', pos + 1);
            if (posEnd === -1) posEnd = UrlService.url.length;
            if (lang === 'vi') {
              while (pos !== -1 && count < 2) {
                pos = UrlService.url.indexOf('/', pos + 1);
                count++;
              }
              if (pos === -1) {
                beforeSecondSlash = UrlService.url;
                afterSecondSlash = '';
              } else {
                beforeSecondSlash = UrlService.url.substring(0, pos);
                afterSecondSlash = UrlService.url.substring(pos, posEnd);
              }

              beforeSecondSlash = beforeSecondSlash
                .replace('apartment-list', 'thue-can-ho')
                .replace('/en/', '/');
            } else {
              UrlService.url = UrlService.url.replace('/en/', '/');
              while (pos !== -1 && count < 2) {
                pos = UrlService.url.indexOf('/', pos + 1);
                count++;
              }
              if (pos === -1) {
                beforeSecondSlash = UrlService.url;
                afterSecondSlash = '';
              } else {
                beforeSecondSlash = UrlService.url.substring(0, pos);
                afterSecondSlash = UrlService.url.substring(pos, posEnd);
              }
              beforeSecondSlash = beforeSecondSlash.replace(
                'thue-can-ho',
                'apartment-list'
              );
              if (!beforeSecondSlash.includes('/en/'))
                beforeSecondSlash = '/en' + beforeSecondSlash;
            }
            url = beforeSecondSlash + afterSecondSlash;
          } else if (UrlService.url.startsWith('/404') ||
            UrlService.url.startsWith('/en/404'))
            url = UrlService.url;

          if (UrlService.switchLang && url !== UrlService.url) {
            this.activatedRoute.queryParams
              .pipe(take(1))
              .subscribe((params) => {
                this.router.navigate([`/${url}`], {
                  queryParamsHandling: 'preserve'
                });
              });

            UrlService.switchLang = false;
          }
          if (isPlatformBrowser(this.platformId)) {
            this.cookieService.set(
              '.AspNetCore.Culture',
              'c=' +
              this.translateService.currentLang +
              '|uic=' +
              this.translateService.currentLang,
              {
                expires: 7,
                path: '/',
                domain: null,
                secure: true,
                sameSite: 'Strict'
              }
            );
          }
          if (this.authenticationService.getJwt() !== '') {
            this.userService
              .setLanguage(this.translateService.currentLang)
              .subscribe();
          }
        },
        complete: () => {
          this.unsubscribe.forEach((c) => c.unsubscribe());
          this.unsubscribe = [];
        }
      })
    );
  }

  switchLang(lang: string = null) {
    let language;
    if (!lang) {
      language = 'vi';
      if (this.translateService.currentLang === 'vi') {
        language = 'en';
      }
    } else language = lang;

    let url = this.location.path();
    if (url.startsWith('/en')) url = url.substring(3);
    else if (url === '/') url = '';

    UrlService.url = url;
    UrlService.switchLang = true;
    this.SetLang(language);

    if (isPlatformBrowser(this.platformId)) {
      this.document.documentElement.lang = language;
    }
  }

  setLangLoad(lang: string) {
    const locationURL = this.location.path().split('?')[0];

    // For testing purpose, like test showing Google ads
    if (locationURL === '/testing') {
      return;
    }

    UrlService.url = locationURL;
    UrlService.switchLang = true;

    this.SetLang(lang);
  }
}
