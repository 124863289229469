import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SignInService } from './twofa/signin.service';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  public idUser: string;
  private jwt = '';
  private role: string;
  private behaviorLog = new BehaviorSubject(null);

  constructor(
    private cookieService: CookieService,
    private ssrCookieService: SsrCookieService,
    private signInService: SignInService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  SetLogged(value: boolean) {
    this.behaviorLog.next(value);
  }

  GetLogged() {
    return this.behaviorLog.asObservable();
  }

  GetLoggedValue(): boolean {
    return this.behaviorLog.value;
  }

  public getJwt() {
    if (this.jwt.length === 0) {
      if (isPlatformBrowser(this.platformId))
        this.jwt = this.cookieService.get(environment.jwtName);
      else
        this.jwt = this.ssrCookieService.get(environment.jwtName);
    }
    if (this.jwt && this.jwt !== 'null' && this.jwt !== 'undefined') {
      const jwtHelper = new JwtHelperService();
      if (!jwtHelper.isTokenExpired(this.jwt)) {
        this.role = jwtHelper.decodeToken(this.jwt).role;
      } else this.jwt = '';
    } else this.jwt = '';
    return this.jwt;
  }

  public getRole() {
    this.getJwt();
    return this.role;
  }

  public isJwtExpired() {
    this.getJwt();
    if (this.jwt === '') return true;
    const jwtHelper = new JwtHelperService();
    return jwtHelper.isTokenExpired(this.jwt);
  }

  public getIdUser() {
    this.getJwt();
    if (!this.idUser && this.jwt !== '') {
      const jwtHelper = new JwtHelperService();
      this.idUser = jwtHelper.decodeToken(this.jwt).unique_name;
    }
    return this.idUser;
  }

  login(token: string) {
    this.cookieService.set(environment.jwtName, token, {
      expires: 7,
      path: '/',
      domain: null,
      secure: true,
      sameSite: 'Strict'
    });
    this.SetLogged(true);
  }

  logout() {
    console.log(environment.jwtName);
    this.cookieService.delete(environment.jwtName, "/", null, true, "Strict");
    this.jwt = '';
    this.SetLogged(false);
    this.signInService.signOut().subscribe();
  }
}
