import { dateToUtc } from "../../helpers/utcDate";
import { StarsReview } from "../ads/starReviews";

export class UserProfil {
  public id: string;
  public firstName: string;
  public lastName: string;
  public phone: string;
  public email: string;
  public twoFaType: number;
  public profilImage: string;
  public creation: Date;
  public starsRating: number;
  public quantityStars: number;
  public starsReview: StarsReview[];
  public facebook: string;
  public website: string;

  public static fromJson(json: object): UserProfil {
    const user = new UserProfil();
    if (json !== null) {
      Object.assign(user, json);
      user.creation = dateToUtc(new Date(json['creation']));
    }

    return user;
  }

}
