import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { DataService } from './service/data.service';
import { ExchangeRateService } from './service/exchangeRate.service';
import { ProgressSpinnerComponent } from './spinner/progress-spinner.component';
import { ToastShowComponent } from './spinner/toastShow.component';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { SessionService } from './service/SessionService';
import { UserService } from './service/user.service';
import { AuthenticationService } from './service/authentication.service';
import { Meta } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { UrlService } from './service/url.service';
import { DOCUMENT, Location, isPlatformBrowser } from '@angular/common';
//import { LogService } from './service/logs.service';

setCulture('vi');

L10n.load({
  vi: {
    grid: {
      EmptyRecord: 'Aucun enregistrement affiché'
    },
    pager: {
      currentPageInfo: '{0} de {1} pages',
      pagerDropDown: 'objets par page',
      pagerAllDropDown: 'objets',
      totalItemsInfo: '	({0} objets)',
      totalItemInfo: '	({0} objet)'
    }
  },
  en: {
    grid: {
      EmptyRecord: 'No records displayed'
    },
    pager: {
      currentPageInfo: '{0} of {1} pages',
      pagerDropDown: 'items by page',
      pagerAllDropDown: 'items',
      totalItemsInfo: '	({0} items)',
      totalItemInfo: '	({0} item)'
    }
  }
});

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [ProgressSpinnerComponent, ToastShowComponent, RouterModule]
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = [];
  private cssLink: HTMLLinkElement | null = null;

  constructor(
    private translate: TranslateService,
    private cookieService: CookieService,
    private ssrCookieService: SsrCookieService,
    private router: Router,
    private dataService: DataService,
    private exchangeRateService: ExchangeRateService,
    private sessionService: SessionService,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private metaTagService: Meta,
    private urlService: UrlService,
    private location: Location,
    private renderer: Renderer2,
//    private logService: LogService,
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    this.router.events.subscribe((event) => {
      //this.logService.log(this.safeStringify(event));
      if (event instanceof NavigationEnd) {
        const existingScripts = this.document.querySelectorAll(
          'script[type="application/ld+json"]'
        );

        existingScripts.forEach((script) => {
          this.renderer.removeChild(this.document.head, script);
        });

        let image = '';
        if (this.translate.currentLang === 'vi')
          image = 'https://canhotot.app/assets/img/canhotot_logo_green_vn.webp';
        else
          image = 'https://canhotot.app/assets/img/canhotot_logo_green_en.webp';
        const jsonLdObject = {
          '@context': 'http://schema.org',
          '@type': 'Organization',
          name: 'Canhotot',
          url: 'https://canhotot.app',
          description: this.translate.instant(
            'Rental of apartments, houses and rooms with Canhotot.app!'
          ),
          email: 'info@canhotot.app',
          telephone: '873-886-8657',
          image: image,
          sameAs: [
            'https://www.facebook.com/canhotot.app',
            'https://twitter.com/innologiciel'
          ],
          address: {
            '@type': 'PostalAddress',
            streetAddress: '53 17e avenue',
            addressLocality: 'Drummondville',
            addressCountry: 'CA',
            addressRegion: 'Centre du Québec',
            postalCode: 'J2B 3J5'
          },
          logo: image
        };

        const script = this.renderer.createElement('script');

        this.renderer.setAttribute(script, 'type', 'application/ld+json');
        script.textContent = JSON.stringify(jsonLdObject);
        this.renderer.appendChild(this.document.head, script);
      }
    });
  }
  safeStringify(obj) {
    const seen = new WeakSet();
    return JSON.stringify(obj, (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    });
  }
  ngOnInit() {
    let lang = '';
    let aspNetCulture = '';
    if (isPlatformBrowser(this.platformId)) {
      aspNetCulture = this.cookieService.get('.AspNetCore.Culture');
    } else {
      aspNetCulture = this.ssrCookieService.get('.AspNetCore.Culture');
    }

    if (aspNetCulture) {
      lang = aspNetCulture.substring(2, 4);
      if (lang !== 'en' && lang !== 'vi') lang = 'vi';
      const url = this.location.path();

      if (url.startsWith('/en')) lang = 'en';
    } else {
      this.translate.addLangs(['vi', 'en']);
      this.translate.setDefaultLang('vi');
      const browserLang = this.translate.getBrowserLang();
      const url = this.location.path();

      if (url.startsWith('/en')) lang = 'en';

      if (lang === '') lang = browserLang?.match(/en|vi/) ? browserLang : 'vi';
    }
    this.translate.use(lang);
    if (this.location.path() === '') {
    } else {
      if (isPlatformBrowser(this.platformId)) {
        this.cookieService.set(
          '.AspNetCore.Culture',
          'c=' + lang + '|uic=' + lang,
          {
            expires: 7,
            path: '/',
            domain: null,
            secure: true,
            sameSite: 'Strict'
          }
        );
      }
    }

    if (environment.hideGoogleIndex)
      this.metaTagService.addTags([{ name: 'robots', content: 'noindex' }]);

    this.sessionService.checkAndRecordSession();

    if (this.authenticationService.getJwt() !== '') {
      this.userService.getCurrency().subscribe({
        next: (val) => {
          if (+val === 2) {
            this.exchangeRateService.getExchangeRate().subscribe({
              next: (val) => {
                this.dataService.SetExchangeRate(val);
              }
            });
            this.dataService.SetCurrency(val);
          }
        }
      });
    } else {
      this.dataService.SetCurrency(1);
    }

    this.urlService.setLangLoad(lang);

    if (isPlatformBrowser(this.platformId)) {
      const html = this.document.documentElement;
      html.setAttribute('lang', lang);
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((s) => s.unsubscribe());
    if (this.cssLink) {
      this.renderer.removeChild(this.document.head, this.cssLink);
      this.cssLink = null;
    }
  }
}
