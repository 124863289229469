
export class TwoFa {
  public twoFaType: number;
  public gaActivated: number;

  public static fromJson(json: object): TwoFa {
    const auth = new TwoFa();
    auth.twoFaType = json['twoFaType'];
    auth.gaActivated = json['gaActivated'];

    return auth;
  }
}
