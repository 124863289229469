
export class UserSetting {
  public emailNewAppart: boolean;
  public montlhyReport: boolean;

  public static fromJson(json: object): UserSetting {
    const userSetting = new UserSetting();
    userSetting.emailNewAppart = json['emailNewAppart'];
    userSetting.montlhyReport = json['montlhyReport'];
    return userSetting;
  }
}
