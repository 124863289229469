import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SignInService {
  constructor(private http: HttpClient) {}

  PasswordSignIn(email: string, password: string): Observable<object> {
    return this.http
      .post('/api/SignIn/PasswordSignIn', { email: email, password: password },
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
        })
      .pipe(data => data);
  }

  Google(idToken: string): Observable<object> {
    return this.http
      .post('/api/SignIn/Google', { idToken: idToken})
      .pipe(data => data);
  }

  TwoFactorAuthenticatorSignIn(code: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('code', code);
    return this.http
      .get('/api/SignIn/TwoFactorAuthenticatorSignIn', {
        params: params,
        responseType: 'text'
      })
      .pipe((val) => val);
  }

  prinPdft(): Observable<object> {
    return this.http.get('/api/SignIn/PdfPrint').pipe(data => data);
  }

  signOut(): Observable<object> {
    return this.http.get('/api/SignIn/SignOut').pipe(data => data);
  }

  refresh(): Observable<object> {
    return this.http.get('/api/SignIn/Refresh').pipe(data => data);
  }
}
