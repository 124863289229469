import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ExchangeRateService {
  constructor(private http: HttpClient) {
  }


  getExchangeRate(): Observable<number>  {
    return this.http.get<number>('/api/exchange/rate');
  }

}
