import { Routes } from '@angular/router';
import { AuthGuard } from './helpers/auth.guard';
import { Role } from './enum/role';

export const routes: Routes = [
  {
    path: 'chitiet/:type/:city/:id',
    loadComponent: () =>
      import('./canhoDetail/appartDetail.component').then(
        (mod) => mod.AppartDetailComponent
      ),
    data: { roles: [Role.None] }
  },
  {
    path: 'chitiet/:type/:id',
    loadComponent: () =>
      import('./canhoDetail/appartDetail.component').then(
        (mod) => mod.AppartDetailComponent
      ),
    data: { roles: [Role.None] }
  },
  {
    path: 'chitiet/:id',
    loadComponent: () =>
      import('./canhoDetail/appartDetail.component').then(
        (mod) => mod.AppartDetailComponent
      ),
    data: { roles: [Role.None] }
  },
  {
    path: 'chinh-sach-bao-mat',
    loadComponent: () =>
      import('./policy/policy.component').then((mod) => mod.PolicyComponent),
    data: { data: 'privacypolicy', roles: [Role.None] }
  },
  {
    path: 'faq',
    loadComponent: () =>
      import('./policy/policy.component').then((mod) => mod.PolicyComponent),
    data: { data: 'faq', roles: [Role.None] }
  },
  {
    path: 'dieu-kien',
    loadComponent: () =>
      import('./policy/policy.component').then((mod) => mod.PolicyComponent),
    data: { data: 'terms', roles: [Role.None] }
  },
  {
    path: 'lien-he',
    loadComponent: () =>
      import('./contactus/contactus.component').then(
        (mod) => mod.ContactUsComponent
      )
  },
  {
    path: '404',
    loadComponent: () =>
      import('./notFound/notFound.component').then(
        (mod) => mod.NotFoundComponent
      )
  },
  {
    path: '',
    loadComponent: () =>
      import('./home/home.component').then((mod) => mod.HomeComponent)
  },
  {
    path: 'cho-thue-can-ho',
    loadComponent: () =>
      import('./map/map.component').then((mod) => mod.MapComponent)
  },
  {
    path: 'thue-can-ho/:province/:mrc/:city',
    loadComponent: () =>
      import('./statistic/city/city.component').then((mod) => mod.CityComponent)
  },
  {
    path: 'thue-can-ho/:province/:mrc',
    loadComponent: () =>
      import('./statistic/city/city.component').then((mod) => mod.CityComponent)
  },
  {
    path: 'thue-can-ho/:province',
    loadComponent: () =>
      import('./statistic/province.component').then((mod) => mod.StatisticComponent)
  },
  {
    path: 'thue-can-ho',
    loadComponent: () =>
      import('./statistic/province.component').then((mod) => mod.StatisticComponent)
  },
  {
    path: 'manage',
    loadComponent: () =>
      import('./manage/manage.component').then((mod) => mod.ManageComponent),
    children: [
      {
        path: 'setting',
        loadComponent: () =>
          import('./manage/setting/setting.component').then(
            (mod) => mod.SettingComponent
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User],
          isPage: true
        }
      }
    ]
  },
  {
    path: 'en/chitiet/:type/:city/:id',
    loadComponent: () =>
      import('./canhoDetail/appartDetail.component').then(
        (mod) => mod.AppartDetailComponent
      ),
    data: { roles: [Role.None] }
  },
  {
    path: 'en/chitiet/:type/:id',
    loadComponent: () =>
      import('./canhoDetail/appartDetail.component').then(
        (mod) => mod.AppartDetailComponent
      ),
    data: { roles: [Role.None] }
  },
  {
    path: 'en/chitiet/:id',
    loadComponent: () =>
      import('./canhoDetail/appartDetail.component').then(
        (mod) => mod.AppartDetailComponent
      ),
    data: { roles: [Role.None] }
  },
  {
    path: 'en/privacy-policy',
    loadComponent: () =>
      import('./policy/policy.component').then((mod) => mod.PolicyComponent),
    data: { data: 'privacypolicy', roles: [Role.None] }
  },
  {
    path: 'en/faq',
    loadComponent: () =>
      import('./policy/policy.component').then((mod) => mod.PolicyComponent),
    data: { data: 'faq', roles: [Role.None] }
  },
  {
    path: 'en/terms',
    loadComponent: () =>
      import('./policy/policy.component').then((mod) => mod.PolicyComponent),
    data: { data: 'terms', roles: [Role.None] }
  },
  {
    path: 'en/contact-us',
    loadComponent: () =>
      import('./contactus/contactus.component').then(
        (mod) => mod.ContactUsComponent
      )
  },
  {
    path: 'en/404',
    loadComponent: () =>
      import('./notFound/notFound.component').then(
        (mod) => mod.NotFoundComponent
      )
  },
  {
    path: 'en',
    loadComponent: () =>
      import('./home/home.component').then((mod) => mod.HomeComponent)
  },
  {
    path: 'en/apartment-rental',
    loadComponent: () =>
      import('./map/map.component').then((mod) => mod.MapComponent)
  },
  {
    path: 'en/apartment-list/:province/:mrc/:city',
    loadComponent: () =>
      import('./statistic/city/city.component').then((mod) => mod.CityComponent)
  },
  {
    path: 'en/apartment-list/:province/:mrc',
    loadComponent: () =>
      import('./statistic/city/city.component').then((mod) => mod.CityComponent)
  },
  {
    path: 'en/apartment-list/:province',
    loadComponent: () =>
      import('./statistic/province.component').then((mod) => mod.StatisticComponent)
  },
  {
    path: 'en/apartment-list',
    loadComponent: () =>
      import('./statistic/province.component').then((mod) => mod.StatisticComponent)
  },
  {
    path: 'en/manage',
    loadComponent: () =>
      import('./manage/manage.component').then((mod) => mod.ManageComponent),
    children: [
      {
        path: 'setting',
        loadComponent: () =>
          import('./manage/setting/setting.component').then(
            (mod) => mod.SettingComponent
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User],
          isPage: true
        }
      }
    ]
  },
  {
    path: 'en/**',
    redirectTo: 'en/404'
  },
  {
    path: '**',
    redirectTo: '404'
  }
];
