import { PLATFORM_ID, inject } from '@angular/core';
import { HttpRequest, HttpHandlerFn } from '@angular/common/http';
import { AuthenticationService } from '../service/authentication.service';
import { isPlatformBrowser } from '@angular/common';

export function JwtInterceptor(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
) {
  const authenticationService = inject(AuthenticationService);
  const jwt = authenticationService.getJwt();
  const platformId = inject(PLATFORM_ID);
  const isBrowser = isPlatformBrowser(platformId);

  if (isBrowser) {
    if (!jwt || jwt === 'null' || jwt === 'undefined') {
      request = request.clone();
    } else {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${jwt}`
        }
      });
    }
  } else {
    request = request.clone({
      withCredentials: true,
      headers: request.headers.set('g', authenticationService.getJwt())
    });
  }
  return next(request);
}
