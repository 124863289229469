export class Reviews {
  public firstName: number;
  public star: number;
  public updateStar: Date;
  public idUser: string;

  public static fromJson(json: object): Reviews {
    const review = new Reviews();
    review.firstName = json['firstName'];
    review.star = json['star'];
    review.updateStar = new Date(json['updateStar']);
    review.idUser = json['idUser'];
    return review;
  }
}
