import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  private logs: string[] = [];
  private isServer: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    this.isServer = isPlatformServer(this.platformId);
  }

  log(message: string) {
    const logMessage = this.isServer ? `SSR: ${message}` : `Client: ${message}`;
    this.logs.push(logMessage);
    console.log(logMessage);
  }

  getLogs(): string[] {
    return this.logs;
  }
}
