import { Injectable, ErrorHandler, inject, PLATFORM_ID } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from '../../environments/environment';
import { browserTracingIntegration } from '@sentry/angular-ivy';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
    Sentry.init({
      dsn: 'https://0351d31ae87948cab6af5fcb81cfc7cc@o203305.ingest.us.sentry.io/5509050',
      maxValueLength: 100000,
      beforeSend(event) {
        // Modify the event here
        try {
          const platformId = inject(PLATFORM_ID);
          const isBrowser = isPlatformBrowser(platformId);
          if (isBrowser) {
            const lastFrame =
              event['exception']['values'][0]['stacktrace']['frames'][
                event['exception']['values'][0]['stacktrace']['frames'].length -
                  1
              ];

            if (lastFrame['filename'].includes('ng-cli-ws')) {
              return null;
            }
            console.log(
              '************' +
                event['exception']['values'][0]['value'] +
                '************'
            );
            console.log(
              '************' +
                event['exception']['values'][0]['stacktrace']['frames'][
                  event['exception']['values'][0]['stacktrace']['frames']
                    .length - 1
                ]['filename'] +
                '************'
            );
          } else {
            //const logservice = inject(LogService)
            //logservice.log(event["exception"]["values"][0]["value"])
          }
          return event;
        } catch {
          return event;
        }
      },
      integrations: [browserTracingIntegration()],
      tracePropagationTargets: [/https:\/\/canhotot\.app\/api/],
      tracesSampleRate: environment.tracesSampleRate, // We recommend adjusting this in production
      replaysSessionSampleRate: environment.replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: environment.replaysOnErrorSampleRate // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }

  handleError(error) {
    Sentry.captureException(error.originalError || error);
  }
}
