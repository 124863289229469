import { HttpRequest, HttpHandlerFn } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { catchError, concatMap, delay, retryWhen } from 'rxjs/operators';
import { inject } from '@angular/core';
import { environment } from '../../environments/environment';
import { ToastService } from '../service/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from '../service/spinner.service';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { LogService } from '../service/logs.service';

export const retryCount = 1;
export const retryWaitMilliSeconds = 1000;
export function ErrorInterceptor(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
) {
  const router = inject(Router);
  const toastService = inject(ToastService);
  const translateService = inject(TranslateService);
  const spinnerService = inject(SpinnerService);
  const logService = inject(LogService);
  return next(request)
    .pipe(
      retryWhen((error) =>
        error.pipe(
          concatMap((error, count) => {
            if (
              count <= retryCount &&
              (error.status === 0 ||
                error.status === 504 ||
                (error.status === 400 && !error.error))
            ) {
              return of(error);
            }
            return throwError(error);
          }),
          delay(retryWaitMilliSeconds)
        )
      )
    )
    .pipe(
      catchError((err) => {
        spinnerService.hide();
        function safeStringify(obj) {
          const seen = new WeakSet();
          return JSON.stringify(obj, (key, value) => {
            if (typeof value === "object" && value !== null) {
              if (seen.has(value)) {
                return;
              }
              seen.add(value);
            }
            return value;
          });
        }
        logService.log(safeStringify(err));
        if ([401, 403].indexOf(err.status) !== -1) {
          if (environment.production) router.navigate(['/404']);
          // auto logout if 401 response returned from api
          //  authenticationService.logout(null);
        } else if (err.status === 410) {
          return throwError(err);
        } else if (err.status !== 0) {
          Sentry.setContext('extra', {
            responseType: request.responseType,
            body: JSON.stringify(request.body),
            status: err.status,
            url: request.url,
            urlWithParams: request.urlWithParams
          });
          Sentry.captureException(err);
        }

        if (err.status === 404 && environment.production)
          router.navigate(['/404']);

        const error = JSON.stringify(err);
        console.log(error);
        let erreur;
        if (!err.error) erreur = err.message;
        else erreur = err.error.error ? err.error.error : err.error;
        if (erreur instanceof Blob) {
          const reader = new FileReader();
          reader.onload = () => {
            erreur = reader.result;
            if (!environment.production) {
              toastService.showError('Erreur', erreur);
            } else
              toastService.showError(translateService.instant('Error'), erreur);
          };
          reader.readAsText(erreur);
        } else {
          if (err.status === 504) {
            toastService.showError(
              translateService.instant('Timeout Error'),
              ''
            );
          } else if (environment.production && err.status === 500) {
            toastService.showError(
              translateService.instant('Internal Error'),
              ''
            );
          } else if (environment.production && err.status === 400) {
            toastService.showError(translateService.instant('Error'), erreur);
          } else if (environment.production && err.status === 408) {
            toastService.showError(
              translateService.instant('Error'),
              translateService.instant('Error.Timeout.408')
            );
          } else if (
            environment.production &&
            err.status !== 0 &&
            err.status !== 401 &&
            err.status !== 404
          ) {
            toastService.showError(translateService.instant('Error'), '');
          } else if (!environment.production && err.status !== 404) {
            toastService.showError('Erreur', error);
          }
        }
        return throwError(error);
      })
  );

}
