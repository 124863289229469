import { Injectable, OnDestroy, inject } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  RouteConfigLoadStart,
  RouteConfigLoadEnd,
  CanActivateFn
} from '@angular/router';
import { AuthenticationService } from '../service/authentication.service';
import { SpinnerService } from '../service/spinner.service';
import { Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
class PermissionsService implements OnDestroy {
  private unsubscribe: Subscription[] = [];

  ngOnDestroy() {
    this.unsubscribe.forEach((s) => s.unsubscribe());
  }

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private spinnerService: SpinnerService
  ) {
    // to show a spinner on lazy load route
    this.unsubscribe.push(
      router.events.subscribe((event) => {
        if (event instanceof RouteConfigLoadStart) {
          this.spinnerService.show();
        } else if (event instanceof RouteConfigLoadEnd) {
          this.spinnerService.hide();
        }
      })
    );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (route.data.roles.indexOf('None') !== -1) {
      return true;
    }

    let role = null;
    if (!this.authenticationService.isJwtExpired()) {
      role = this.authenticationService.getRole();
    }

    if (role) {
      // check if route is restricted by role
      if (route.data.roles && route.data.roles.includes(role) === -1) {
        // role not authorised so redirect to home page
        this.router.navigate(['/']);
        this.spinnerService.hide();
        return false;
      }
      return true;
    }
    if (route.routeConfig.path === 'login') {
      this.spinnerService.hide();
      return true;
    }
    this.spinnerService.hide();
    // not logged in so redirect to login page with the return url
    this.authenticationService.logout();
    this.router.navigate(['/'], {
      queryParams: { redirect: state.url.replace('/', '') }
    });
    return false;
  }
}

export const AuthGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean => {
  return inject(PermissionsService).canActivate(next, state);
};
