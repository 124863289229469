import { dateToUtc } from "../../helpers/utcDate";

export class UserProfilAgent {
  public firstName: string;
  public lastName: string;
  public phone: string;
  public email: string;
  public profilImage: string;
  public creation: Date;
  public facebook: string;
  public website: string;

  public static fromJson(json: object): UserProfilAgent {
    const user = new UserProfilAgent();
    Object.assign(user, json);
    user.creation = dateToUtc(new Date(json['creation']));
    return user;
  }

}
