import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private sessionStarted = false;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {}

  checkAndRecordSession() {
    if (!this.sessionStarted && this.authenticationService.getJwt() !== '') {
      this.recordSession();
    }
  }

  private recordSession() {
    this.http.patch('api/Session/Record', {}).subscribe({
      next: () => {
        this.sessionStarted = true;
      }
    });
  }
}
