
export class UserReturnLogin {
  public id: string;
  public inactivity: number;
  public token: string;
  public actif: number;
  public message: string;

  public static fromJson(json: object): UserReturnLogin {
    const user = new UserReturnLogin();
    Object.assign(user, json);
    return user;
  }

}
