import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GoogleMapStats } from '../dto/map/googleMapStats';
import { MapInfos } from '../dto/map/mapInfos';
import { Research } from '../dto/userSave/research';

@Injectable({ providedIn: 'root' })
export class DataService {

  private empty = undefined;
  private False = null;
  private String = '';

  public pages = 1;
  public char: string;

  private behaviorIndice = new BehaviorSubject(this.False);
  private behaviorTypeSector = new BehaviorSubject(this.String);
  private behaviorGoogleStats = new BehaviorSubject(new GoogleMapStats());
  private behaviorMapInfos = new BehaviorSubject(this.empty);
  private behaviorExpandAdvanceSearch = new BehaviorSubject(this.empty);
  private behaviorQuantityAdvanceSearch = new BehaviorSubject(this.empty);
  private behaviorLoadResearch = new BehaviorSubject(this.empty);
  private behaviorCurrency = new BehaviorSubject(this.empty);
  private behaviorExchangeRate = new BehaviorSubject(this.empty);
  private behaviorAddress = new BehaviorSubject(this.empty);
  private behaviorPointToSelect = new BehaviorSubject(this.empty);


  SetPointToSelect(value) {
    this.behaviorPointToSelect.next(value);
  }

  GetPointToSelectValue() {
    return this.behaviorPointToSelect.value;
  }

  SetAddress(value) {
    this.behaviorAddress.next(value);
  }

  GetAddress() {
    return this.behaviorAddress.asObservable();
  }

  SetCurrency(value) {
    this.behaviorCurrency.next(value);
  }

  GetCurrency() {
    return this.behaviorCurrency.asObservable();
  }

  GetCurrencyValue() {
    return this.behaviorCurrency.value;
  }

  SetExchangeRate(value) {
    this.behaviorExchangeRate.next(value);
  }

  GetExchangeRate() {
    return this.behaviorExchangeRate.asObservable();
  }

  GetExchangeRateValue() {
    return this.behaviorExchangeRate.value;
  }

  SetLoadResearch(value: Research) {
    this.behaviorLoadResearch.next(value);
  }

  GetLoadReseach(): Observable<Research> {
    return this.behaviorLoadResearch.asObservable();
  }

  SetExpandAdvanceSearch(value: boolean) {
    this.behaviorExpandAdvanceSearch.next(value);
  }

  GetExpandAdvanceSearch() {
    return this.behaviorExpandAdvanceSearch.asObservable();
  }

  SetQuantityAdvanceSearch(value: number) {
    this.behaviorQuantityAdvanceSearch.next(value);
  }

  GetQuantityAdvanceSearch() {
    return this.behaviorQuantityAdvanceSearch.asObservable();
  }

  SetShowInfoOnMobile(value: boolean, doc: Document) {
    const DOM_checkbox = doc.getElementById(
      'openPopupInfoMobile'
    ) as HTMLInputElement;
    if (DOM_checkbox) DOM_checkbox.checked = value;
  }

  SetIndice(value: boolean) {
    this.behaviorIndice.next(value);
  }

  SetTypeSector(value: string) {
    this.behaviorTypeSector.next(value);
  }

  SetMapInfos(value: MapInfos) {
    this.behaviorMapInfos.next(value);
  }

  GetGoogleStatsValue() {
    return this.behaviorGoogleStats.value;
  }

  GetMapInfosValue(): MapInfos {
    return this.behaviorMapInfos.value;
  }

  GetIndice() {
    return this.behaviorIndice.asObservable();
  }
}
