import { AfterViewInit, Component, Inject, OnDestroy, PLATFORM_ID, ViewChild } from '@angular/core';
import { ToastComponent } from '@syncfusion/ej2-angular-notifications';
import { Subscription, timeout } from 'rxjs';
import { ToastService } from '../service/toast.service';
import { ToastModule } from '@syncfusion/ej2-angular-notifications';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-toast',
  templateUrl: 'toastShow.component.html',
  styleUrls: ['toastShow.component.scss'],
  standalone: true,
  imports: [ToastModule, CommonModule, TranslateModule]
})
export class ToastShowComponent implements OnDestroy, AfterViewInit {
  public showProgressBar = true;
  @ViewChild('toasttype')
  private toastObj: ToastComponent;
  public position = { X: 'Right', Y: 'Top' };
  public dataToast = null;
  public title: string;
  public content: string;
  private unsubscribe: Subscription[] = [];
  constructor(private toastService: ToastService,
    @Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnDestroy() {
    this.unsubscribe.forEach((s) => s.unsubscribe());
  }

  ngAfterViewInit() {
    this.unsubscribe.push(
      this.toastService.target.subscribe((val) => {
        if (val && val !== '') this.toastObj.target = val;
      })
    );

    this.unsubscribe.push(
      this.toastService.visibility.subscribe((val) => {
        if (val) {
          if (!this.toastObj.target) this.toastObj.target = 'toast_target';
          this.title = val[0].title.toString();
          this.content = val[0].content.toString();
          this.dataToast = val[0];
          if (isPlatformBrowser(this.platformId)) {
            this.toastObj.show();
          }
        }
      })
    );
  }

  closeToast() {
    this.toastObj.hide();
  }

  onBeforeOpen(e) {
    if (1 === this.toastObj.element.childElementCount) {
      e.cancel = true;
    } else {
      e.cancel = false;
    }
  }
}
