import {
  APP_INITIALIZER,
  enableProdMode,
  ErrorHandler,
  importProvidersFrom,
  LOCALE_ID,
  TransferState
} from '@angular/core';
import { environment } from './environments/environment';
import {
  bootstrapApplication,
  provideClientHydration
} from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { CookieService } from 'ngx-cookie-service';
import {
  HttpClient,
  provideHttpClient,
  withInterceptors
} from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { routes } from './app/app-routing';
import { Router, RouterModule } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular-ivy';
import { SentryErrorHandler } from './app/helpers/sentry.ErrorHandler';
import { ErrorInterceptor } from './app/helpers/error.interceptor';
import { JwtInterceptor } from './app/helpers/jwt.interceptor';
import { registerLicense } from '@syncfusion/ej2-base';
import { provideGoogleAnalytics } from '@hakimio/ngx-google-analytics';

registerLocaleData(localeFr);

registerLicense(
  'ORg4AjUWIQA/Gnt2UVhiQllPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9nSX1Td0RkW3ZceXRdR2Q='
);

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled',
        onSameUrlNavigation: 'reload',
        enableTracing: environment.tracing
      })
    ),
    { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
    CookieService,
    provideHttpClient(withInterceptors([JwtInterceptor, ErrorInterceptor])),
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'vi' },
    provideGoogleAnalytics(environment.googleAnalytics),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient, TransferState]
        }
      })
    ),
    provideClientHydration()
  ]
}).catch((err) => console.log(err));

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}
